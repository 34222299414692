import FundUnicefKrTemplate from '@/page-blocks/about-us/clear/fund/unicef-kr/FundUnicefKrTemplate';
import ReportKorea2021 from '@/page-blocks/about-us/clear/fund/unicef-kr/report.korea_2021';
import { PageProps } from 'gatsby';
import React from 'react';

const FundUnicefKr2021: React.FC<PageProps> = (props) => (
  <FundUnicefKrTemplate year={2021} {...props}>
    <ReportKorea2021 />
  </FundUnicefKrTemplate>
);

export default FundUnicefKr2021;
